import {format, parse} from 'date-fns';
import TDSignal from '../components/TDSignal';
import {HOLIDAYS_SET} from './constants';

export function json_parse(str) {
  if (isNullOrUndefined(str)) {
    return []
  }
  return JSON.parse(str)
}

export function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

export function isEmptyOrUndefined(obj) {
  return obj === undefined || obj === null || Object.keys(obj).length === 0;
}

export function format_float(number, digits=1) {
  if (isNullOrUndefined(number)) {
    return ''
  }
  return parseFloat(number).toFixed(digits)
}

export function format_price(number) {
  if (isNullOrUndefined(number)) {
    return ''
  }
  return number.toFixed(2)
}

export function format_date(dateStr, old_format='yyyyMMdd', new_format='dd/MM') {
  if (isNullOrUndefined(dateStr)) {
    return ''
  }
  try {
    const date = parse(dateStr, old_format, new Date())
    return format(date, new_format)
  } catch (e) {
    return dateStr
  }
}

export function count_weekdays(fromDate, toDate) {
  let count = 0;
  let tmp = new Date(fromDate);
  while (tmp <= toDate) {
    if (!isWeekend(tmp) && !isHoliday(tmp)) {
      count += 1;
    }
    tmp.setDate(tmp.getDate() + 1);
  }
  return count - 1;
}


export function isWeekend(date) {
  const day = date.getDay();
  return day === 0 || day === 6;
}

export function isHoliday(date) {
  return HOLIDAYS_SET.has(date.getTime());
}

export function getPreviousWeekdays(fromDate, no) {
  fromDate.setHours(0,0,0,0)
  const previousWeekdays = [];
  if (!isWeekend(fromDate) && !isHoliday(fromDate)) {
    previousWeekdays.push(new Date(fromDate));
  }
  let currentDate = new Date(fromDate);
  while (previousWeekdays.length < no) {
    currentDate.setDate(currentDate.getDate() - 1);
    if (!isWeekend(currentDate) && !isHoliday(currentDate)) {
      previousWeekdays.push(new Date(currentDate));
    }
  }
  return previousWeekdays;
}

export function is_prod() {
  return process.env.REACT_APP_IS_PROD === 'true'
}

export function format_rank(number) {
  if (number > 0) {
    return '+' + number
  } else if (number < 0) {
    return number
  }
  return ''
}

export function format_giaodichnn(number) {
  if (number === 0) {
    return ''
  }
  return number
}

export function class_text_danger(value, max=0) {
  if (value < max) {
    return " text-danger"
  }
  return ""
}

export function class_price_percent(value) {
  if (value === 0) {
    return " text-warning"
  } else if (value >= 0.067) {
    return " text-purple"
  } else if (value <= -0.067) {
    return " text-info"
  } else if (value > 0) {
    return " text-success"
  } else {
    return " text-danger"
  }
}

export function is_own(ticker) {
  const MY_CATALOG = JSON.parse(window.sessionStorage.getItem('my_catalog'))['own']
  for (const i in MY_CATALOG) {
    if (MY_CATALOG[i] === ticker) {
      return true
    }
  }
  return false
}

export function is_v30(ticker) {
  const vn30 = JSON.parse(window.sessionStorage.getItem('vn30'))
  return vn30.includes(ticker)
}

export function class_decoration_line(value) {
  if (!isNullOrUndefined(value)) {
    return " text-decoration-line-through"
  }
  return ""
}

export const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

export function has_margin(ticker) {
  const margin_allowance = JSON.parse(window.sessionStorage.getItem('margin_allowance'))
  return margin_allowance[ticker]
}

export function margin_remain(ticker) {
  const margin = has_margin(ticker)
  if (margin) {
    return margin.room
  } else {
    return null
  }
}

export function get_floor(ticker) {
  const all_tickers = JSON.parse(window.localStorage.getItem('all_tickers'))
  ticker = all_tickers[ticker]
  if (ticker !== undefined) {
    if (ticker.exchange === 'HNX') {
      return 'HNX'
    } else if (ticker.exchange === 'UPCOM') {
      return 'UPC'
    }
  }
  return ''
}

export function cal_price_percent(pricePercent) {
  if (isNullOrUndefined(pricePercent)) {
    return ''
  }
  return format_float(pricePercent * 100) + ' %'
}

export function map_industry(industry) {
  switch(industry) {
    case 'Bất động sản công nghiệp': {
      return 'BĐS CN'
    }
    case 'Bất động sản': {
      return 'BĐS'
    }
    case 'Dịch vụ tài chính': {
      return 'Finance'
    }
    case 'Nông nghiệp & Chăn nuôi': {
      return 'NN'
    }
    case 'Phần mềm & DV máy tính': {
      return 'CNTT'
    }
    case 'Thiết bị & Dịch vụ y tế': {
      return 'Y tế'
    }
    case 'Nguyên liệu chế biến': {
      return 'Ng liệu'
    }
    case 'Dịch vụ kho bãi': {
      return 'Kho bãi'
    }
    case 'Điện tử & Thiết bị điện': {
      return 'TB điện'
    }
    case 'Chứng khoán': {
      return 'CK'
    }
    case 'Ngân hàng': {
      return 'Bank'
    }
    case 'Máy công nghiệp': {
      return 'Máy CN'
    }
    case 'Vận tải khách & Du lịch': {
      return 'Travel'
    }
    case 'Hàng gia dụng': {
      return 'Gia dụng'
    }
    case 'Bao bì & Đóng gói': {
      return 'Bao bì'
    }
    case 'Sản xuất giấy': {
      return 'Giấy'
    }
    default: {
      return industry
    }
  }
}

export function renderSignalElement(ticker) {
  if (ticker.signal) {
    return <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
  } else if (ticker.pullback_20) {
    return <TDSignal signal={"PULLBACK 20"} price={ticker.pullback_20_price} priceFlat={ticker.priceFlat}/>
  } else if (ticker.pullback_10) {
    return <TDSignal signal={"PULLBACK 10"} price={ticker.pullback_10_price} priceFlat={ticker.priceFlat}/>
  } else if (ticker.wait_signal) {
    return <TDSignal signal={ticker.wait_signal} price={ticker.wait_price} priceFlat={ticker.priceFlat}/>
  }
  return <TDSignal signal={ticker.signal} price={ticker.gia_thuc_hien} priceFlat={ticker.priceFlat}/>
}

export function trimLongText(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  } else {
    return text;
  }
}

export function percentDiff(value1, value2) {
  if (value1 === 0 && value2 === 0) return 0;
  const difference = value1 - value2;
  return format_float((difference / value2) * 100);
}


export function format_value_equity(ticker) {
  if (isNullOrUndefined(ticker.value)) {
    return ''
  }
  let value = ticker.value
  let _cls = "text-danger"
  if (value.equity_value_room > 0) {
    _cls = "text-success"
  }
  return (
    <span className={_cls}>
      {value.equity_value_min}-{value.equity_value_max} | {value.equity_value_room}%
    </span>
  )
}

export function format_value_asset(ticker) {
  if (isNullOrUndefined(ticker.value) || ticker.industry === 'Ngân hàng') {
    return ''
  }
  let value = ticker.value
  let _cls = "text-danger"
  if (value.asset_value_room > 0) {
    _cls = "text-success"
  }
  return (
    <span className={_cls}>
      {value.asset_value_min}-{value.asset_value_max} | {value.asset_value_room}%
    </span>
  )
}


export function map_signal(signal) {
  switch(signal) {
    case 'SMALL BUY': {
      return 'SMALL B'
    }
    case 'BIG BUY': {
      return 'BIG B'
    }
    case 'CHỜ MUA': {
      return 'WAIT B'
    }
    case 'CHỜ BÁN': {
      return 'WAIT S'
    }
    case 'SMALL SELL': {
      return 'SMALL S'
    }
    case 'BIG SELL': {
      return 'BIG S'
    }
    case 'PULLBACK 20': {
      return 'MA20'
    }
    case 'PULLBACK 10': {
      return 'MA10'
    }
    default: {
      return signal
    }
  }
}

export function format_value_quarter(year, quarter) {
  return `Q${quarter}/${year}`
}

export function format_base(base) {
  if (base === 'CHẶT CHẼ') {
    return <i className="bi bi-check-lg text-success"></i>
  }
}

export function format_trend(trend) {
  if (trend === 'TĂNG') {
    return <i className="bi bi-arrow-up-right text-success"></i>
  } else if (trend === 'GIẢM') {
    return <i className="bi bi-arrow-down-right text-danger"></i>
  } else if (trend === 'TRUNG LẬP') {
    return ''
  }
}
