import {isMobile} from 'react-device-detect';


function Chart() {
  if (isMobile) {
    return <iframe src={`https://chart.finbox.vn/mobile-chart?popup=true&symbol=VNINDEX&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`}/>
  }
  return (
    <iframe src={`https://chart.finbox.vn/?popup=true&symbol=VNINDEX&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`}/>
  );
}

export default Chart;
