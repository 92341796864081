import {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {renderSignalElement,} from '../common/util';
import LoadingSpinner from '../components/LoadingSpinner';
import TDTickerVol from "../components/TDTickerVol";
import TDPrice from "../components/TDPrice";
import CTTService from "../services/CTTService";
import TDGDNN from "../components/TDGDNN";
import ModalService from "../services/ModalService";
import ModalSimple from "../components/ModalSimple";

function V30() {
  const title = 'V30';
  const [isLoading, setLoad] = useState(true);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function fetch_vn30() {
      const vn30 = JSON.parse(window.sessionStorage.getItem('vn30'))
      let data = await CTTService.get_tickers(vn30)
      data.sort((a, b) => {
        return b.pricePercent - a.pricePercent
      })
      setRows(data)
      setLoad(false)
    }
    fetch_vn30()
  }, []);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <button className="btn btn-secondary" onClick={() => ModalService.open(ModalSimple, {ticker: {ticker: 'VN30'}})}>VN30</button>
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered table-sm caption-top">
            <thead>
            <tr>
              <th className="th-ticker-vol">ticker</th>
              <th className="th-signal">signal</th>
              <th className="th-price">price</th>
              <th>nn</th>
            </tr>
            </thead>
            <tbody>
            {
              rows && rows.map((ticker, i) => {
                return (
                  <tr key={i}>
                    <TDTickerVol ticker={ticker} highlight_own={false}/>
                    {renderSignalElement(ticker)}
                    <TDPrice ticker={ticker}/>
                    <TDGDNN ticker={ticker}/>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default V30;
