import {Fragment, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {
  cal_price_percent,
  class_decoration_line,
  class_price_percent,
  class_text_danger,
  count_weekdays,
  format_date,
  format_float,
  format_price, format_value_asset,
  format_value_equity,
  isNullOrUndefined,
  map_industry,
} from '../common/util';
import LoadingSpinner from "../components/LoadingSpinner";
import {parse} from "date-fns";
import TDTicker from "../components/TDTicker";
import CTTService from "../services/CTTService";


function TableHead() {
  return (
    <>
      <thead>
      <tr>
        <th className="th-ticker">ticker</th>
        <th className="th-industry">industry</th>
        <th style={{minWidth: 72}}>date</th>
        <th style={{minWidth: 110}}>price</th>
        <th style={{minWidth: 120}}>value(equity)</th>
        <th style={{minWidth: 115}}>value(asset)</th>
        <th style={{minWidth: 110}}>buyDate</th>
        <th style={{minWidth: 120}}>sellDate</th>
        <th className="th-profit">profit</th>
      </tr>
      </thead>
    </>
  )
}

function format_t(t) {
  if (t === 5 || t === 10) {
    return <span className="p-1 bg-warning text-dark">{t}</span>
  }
  return t
}

function format_action_date(start, end) {
  if (isNullOrUndefined(end)) {
    return '';
  } else if (end === 'Cutloss') {
    return end;
  }
  const startDate = parse(start, 'yyyyMMdd', new Date())
  const endDate = parse(end, 'yyyyMMdd', new Date())
  const t = count_weekdays(startDate, endDate)
  return <div>
    <span className={'float-start'}>{format_date(end)}</span>
    <span className={'float-end'}>{format_t(t)}</span>
  </div>
}

function TableBody({ rows }) {
  if (isNullOrUndefined(rows)) {
    return <></>;
  }

  return (
      <tbody>
      {
        rows && rows.map((row, i) => {
          if (!row.tickerData) {
            return (
              <Fragment key={i}></Fragment>
            )
          }
          return (
            <Fragment key={i}>
              <tr>
                <td colSpan={12}>
                  {row.title}
                  <ul>
                    <li>averageIncome: <span className={class_text_danger(row.averageIncome_)}>{format_float(row.averageIncome_ * 100)} %</span></li>
                    <li><span className={class_text_danger(row.vnindex)}>vnindex: {format_float(row.vnindex * 100)} %</span></li>
                  </ul>
                </td>
              </tr>
              {
                row.tickerData && Object.values(row.tickerData).map((ticker, j) => {
                  return (
                    <tr key={j} className={class_decoration_line(ticker.sellDate)}>
                      <TDTicker ticker={ticker}/>
                      <td className="text-start">{map_industry(ticker.industry)}</td>
                      <td className="text-end">{format_action_date(ticker.buyDate, ticker.date)}</td>
                      <td className={"text-end" + class_price_percent(ticker.pricePercent)}>{format_price(ticker.priceFlat)} ({cal_price_percent(ticker.pricePercent)})</td>
                      <td className="text-end">{format_value_equity(ticker)}</td>
                      <td className="text-end">{format_value_asset(ticker)}</td>
                      <td className="text-start">{format_date(ticker.buyDate)} | {format_float(ticker.buyPrice, 2)}</td>
                      <td className="text-start">{format_action_date(ticker.buyDate, ticker.sellDate)} | {format_float(ticker.sellPrice, 2)}</td>
                      <td className={"text-end" + class_text_danger(ticker.profit)}>{format_float(ticker.profit * 100)} %</td>
                    </tr>
                  )
                })
              }
            </Fragment>
          )
        })
      }
      </tbody>
  );
}

function RecommendCS() {
  const title = 'CS';
  const [isLoading, setLoad] = useState(true);
  const [rowsTC, setRowsTC] = useState([]);
  const [rows50, setRows50] = useState([]);
  const [rowsSUPER, setRowsSUPER] = useState([]);
  const [marginOption, setMarginOption] = useState('margin');

  useEffect(() => {
    CTTService.get_cs('star', marginOption).then(r => {
      setRowsSUPER(r.monthData)
    })
    CTTService.get_cs('top', marginOption).then(r => {
      setRows50(r.monthData.slice(0,2))
    })
    CTTService.get_cs('tc', marginOption).then(r => {
      setRowsTC(r.monthData.slice(0,2))
    })
    setLoad(false)
  }, [marginOption]);

  if (isLoading) {
    return (
      <LoadingSpinner/>
    )
  }

  function changeMargin(e) {
    setMarginOption(e.target.value)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="container-fluid">
        <ul className="nav nav-pills" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="tc-tab" data-bs-toggle="tab" data-bs-target="#tc" type="button" role="tab" aria-controls="tc" aria-selected="false">TC</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link" id="top50-tab" data-bs-toggle="tab" data-bs-target="#top50" type="button" role="tab" aria-controls="top50" aria-selected="false">50</button>
          </li>
          <li className="nav-item" role="presentation">
            <button className="nav-link active" id="super-tab" data-bs-toggle="tab" data-bs-target="#super" type="button" role="tab" aria-controls="super" aria-selected="true">SUPER</button>
          </li>

          <select className="form-select form-select-sm ms-4" aria-label=".form-select-sm" onChange={(e) => changeMargin(e)} defaultValue={"margin"}>
            <option value="all">All</option>
            <option value="normal">Normal</option>
            <option value="margin">Margin</option>
          </select>

        </ul>
        <div className="tab-content" id="myTabContent">
          <div className="tab-pane fade" id="tc" role="tabpanel" aria-labelledby="tc-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={rowsTC}/>
              </table>
            </div>
          </div>
          <div className="tab-pane fade" id="top50" role="tabpanel" aria-labelledby="top50-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={rows50}/>
              </table>
            </div>
          </div>
          <div className="tab-pane fade show active" id="super" role="tabpanel" aria-labelledby="super-tab">
            <div className="table-responsive">
              <table className="table table-striped table-hover table-bordered table-sm caption-top">
                <TableHead />
                <TableBody rows={rowsSUPER}/>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RecommendCS;
