import Modal from 'react-bootstrap/Modal';
import {isMobile} from 'react-device-detect';

function ModalSimple(props) {
  return (
    <Modal
      show={true}
      backdrop="static"
      keyboard={false}
      autoFocus={true}
      size={'xl'}
    >
      <Modal.Header style={{height:"20px"}}>
        <div className="col-3">
          <h4>{props.ticker.ticker}</h4>
        </div>
        <button type="button" className="btn-close" onClick={props.close}></button>
      </Modal.Header>
      {
        isMobile
        ? <iframe src={`https://chart.finbox.vn/mobile-chart?symbol=${props.ticker.ticker}&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`} style={{width: "100%", height: "740px"}}/>
        : <iframe src={`https://chart.finbox.vn/?symbol=${props.ticker.ticker}&interval=1D&dark=true&userId=6532936b8cd6c21f16986da5`} style={{width: "100%", height: "740px"}}/>
      }
    </Modal>
  );

}

export default ModalSimple;
