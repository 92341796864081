import axios from 'axios';

const BASE_URL = 'https://api.codetayto.com/api'
// const BASE_URL = 'http://localhost:1704/api'
const DEFAULT_HEADER = {
  'Content-Type': 'application/json'
}
const CTTService = {

  async get_margin_allowance() {
    let response = await axios.get(`${BASE_URL}/common/tickers-margin`)
    return response.data
  },

  async get_all_tickers() {
    let response = await axios.get(`${BASE_URL}/common/tickers-vals`)
    return response.data
  },

  async get_cs(category, option) {
    let response = await axios.post(`${BASE_URL}/fbox/cs`, { category: category, "_filter": option })
    return response.data
  },

  async get_industry_ranking() {
    let response = await axios.get(`${BASE_URL}/fbox/industry-ranking`)
    return response.data
  },

  async get_tickers_by_group(group) {
    let response = await axios.get(`${BASE_URL}/vnstock/listing/symbols-by-group?group=${group}`)
    return response.data
  },

  async get_my_catalog() {
    let response = await axios.get(`${BASE_URL}/me/tickers-follow`)
    return response.data
  },

  async get_result_summary() {
    let response = await axios.get(`${BASE_URL}/me/result-summary`)
    return response.data
  },

  async get_ticker_history(ticker, exec_type) {
    let response = await axios.post(`${BASE_URL}/me/ticker-history`, { ticker: ticker, "exec_type": exec_type }, {headers: DEFAULT_HEADER});
    return response.data
  },

  async get_margin_allowance_full() {
    let response = await axios.get(`${BASE_URL}/securities/tickers-margin`)
    return response.data
  },

  async get_ticker(ticker) {
    let response = await axios.get(`${BASE_URL}/securities/tickers/${ticker}`)
    return response.data
  },

  async get_tickers(tickers) {
    let response = await axios.post(`${BASE_URL}/securities/tickers-multiple`, JSON.stringify(tickers), {headers: DEFAULT_HEADER});
    return response.data
  },

  async get_tickers_industry(industry) {
    const data = {
      "industry": industry
    }
    let response = await axios.post(`${BASE_URL}/securities/tickers-industry`, JSON.stringify(data), {headers: DEFAULT_HEADER});
    return response.data
  },


  async fetch_summary(day=0, marginOption='all') {
    const body = {
      "_filter": marginOption,
      "day": day,
      "tables": [
        "overview",
        "buy_normal",
        "buy_standard",
        "sell_normal",
        "sell_standard",
        "wait_buy_normal",
        "wait_buy_standard",
        "wait_sell_normal",
        "wait_sell_standard",
        "basis_normal",
        "basis_standard",
        "fs_normal",
        "fs_standard",
        "volume_normal",
        "volume_standard",
        "foreign_buy",
        "foreign_sell",
        "break_up_normal_5",
        "break_up_standard_5",
        "break_down_normal_5",
        "break_down_standard_5",
        "price_up_normal",
        "price_up_standard",
        "price_down_normal",
        "price_down_standard",
        "sector_decrease",
        "sector_increase",
        "pullback_10_normal",
        "pullback_10_standard",
        "pullback_20_normal",
        "pullback_20_standard"
      ]
    }
    let response = await axios.post(`${BASE_URL}/securities/summary`, body)
    return response.data
  },

};

export default CTTService;
